import React from 'react'
import CountUp, { useCountUp } from 'react-countup';
import styles from "./countup.moduls.css"
function Countup() {
    useCountUp({
        ref: 'counter',
        end: 1234567,
        enableScrollSpy: true,
        scrollSpyDelay: 1000,
    });
    return (
        <div className='coupUppage'>
            <div className="app">


                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="countup text-center">
                            <div><span class="lnr lnr-rocket"></span></div>
                            <CountUp className='countnumber' end={400} scrollSpyOnce enableScrollSpy={true} scrollSpyDelay={100} duration={10} />
                            <div className='countuptext'>УСПЕШНЫХ СДЕЛОК</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="countup text-center">
                            <div><span class="lnr lnr-cart"></span></div>
                            <CountUp className='countnumber' end={20} scrollSpyOnce enableScrollSpy={true} scrollSpyDelay={100} duration={10} />
                            <div className='countuptext'>ЛЕТ НА РЫНКЕ</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="countup text-center">
                            <div><span class="lnr lnr-license"></span></div>
                            <CountUp className='countnumber' end={15} scrollSpyOnce enableScrollSpy={true} scrollSpyDelay={100} duration={10} />
                            <div className='countuptext'>ПРЕДПРИЯТИЙ ОСНАЎЕНО</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="countup text-center">
                            <div><span class="lnr lnr-database"></span></div>
                            <CountUp className='countnumber' end={700} scrollSpyOnce enableScrollSpy={true} scrollSpyDelay={100} duration={10} />
                            <div className='countuptext'>ПРОДУКЦИЯ</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Countup