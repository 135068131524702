import React, { useEffect, useState } from 'react'
import styles from "./QuicklistPage.module.css"
import CategoryLeft from '../CategoryLeft/CategoryLeft'
import { Link } from "react-router-dom"
import axios from "axios"
import { useLocation, useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

function QuicklistPage(data, sellers) {
  const params = useParams()
  const location = useLocation();
  const [respons, setrespons] = useState([])
  var searchdata = location.state
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}` + `/pagination` + `?search=${searchdata}`, { //&brands=[magnitiy , dhd, kjshf]
      auth: {
        username: 'magniticadmin',
        password: 'Magnitic??[AWTQZTLW]]'
      }
    })
      .then((res) => {
        setrespons(res.data.results)
      })
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
  }, [searchdata])
  console.log(respons)
  return (
    <div className={styles.quickmainfull}>
      <div className="row">
      <div className={styles.breakcrups} role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/">
        Главная
        </Link>
        <Link
          underline="hover"
          color="inherit"
          to="/product"
        >
          Продукция
        </Link>
        <Typography color="text.primary">{searchdata}</Typography>
      </Breadcrumbs>
  </div>
        <div className="col-lg-3 col-md-12 col-sm-12">
          <div className={styles.AccardionLeft}>
            <CategoryLeft />
          </div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12">

          <div className={styles.product_all}>
            {(respons.length > 0) ? respons.map((e, i) => {
              return (
                <>
                  <div className={styles.Item}>
                    <div className="row">
                      <div className="col-lg-4 col-md-12 col-sm-12 ">
                        <div className={styles.imgmain}>
                          <div><img className={styles.imgdiv} src={e.img} alt="img" /></div></div>
                      </div>

                      <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className={styles.title}>{e.title}</div>
                        <div dangerouslySetInnerHTML={{ __html: e.info }}></div>
                        <div ><span className={styles.category_name}>{e.category.name} </span>
                          <Link to={`/full/${e.id}`} className={styles.link} state={sellers[i]}><button className={styles.btn}>Подробности</button></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            }) : <div></div>}
          </div>
          {/* <div className={styles.contactBottom}>
            <div>Получить последнюю цену? Мы ответим как можно скорее (в течение 12 часов)</div>
            <div className="row">
              <div className="col-6"><input type='text' className={styles.contactinput}/></div>
              <div className="col-6"><input type='text' className={styles.contactinput}/></div>
              <div className="col-12"><input type='text' className={styles.contactinput}/></div>
              <div className='col-12'><input type='text' className={styles.contactinput}/></div>
              <div><textarea name="w-100" id="" cols="100" rows="10"></textarea></div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default QuicklistPage