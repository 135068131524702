import React from 'react'
import styles from './Icons.module.css'
import Fade from 'react-reveal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function Icons({data}) {
  console.log(data)
  return (
    <div className={styles.full}>
    <img className={styles.bg_serves_img} src='./images/bg-serves.jpg'/>
    <Fade bottom className={styles.iconfull}>
      <div className={styles.bgserves}>
      <div className={styles.icontop}>
        {
          data.map((e,i)=>{
            return(
                <div className={styles.iconmain} >
                  
                  <div className={styles.icons_image} >
                  {/* <FontAwesomeIcon icon="fa-solid fa-truck" /> */}
                  <img className={styles.iconimg} src={e.image}/>
                  </div>
                  <div className={styles.icon_content} >
                    <h4>{e.name}</h4>
                    <p>{e.des}</p>
                  </div>
                </div>
            )
          })
        }
      </div>
      </div>
    </Fade>
    </div>
  )
}

export default Icons
