import React,{useEffect,useState} from 'react'
import axios from "axios"
import styles from "./Quicklist.module.css"
import QuicklistPage from '../QuicklistPage/QuicklistPage'
import {Link} from "react-router-dom"
function Quicklist(data) {
    const [classlist, setclasslist] = useState([])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}` + '/class', {
            auth: {
                username: 'magniticadmin',
                password: 'Magnitic??[AWTQZTLW]]'
            }
        })
            .then((res) => {
                setclasslist(res.data)
            })
    }, [])
  return (
    <div className={styles.classlistfull}>
        <div className={styles.title}>БЫСТРЫЕ ССЫЛКИ</div>
        <div className="row mx-auto">
            {classlist.map((e,i) => {
                return(
                    <>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                        <Link className={styles.link} to="/quicklistproduct" state={e.name}>
                            <div className={styles.classlistbox}>
                            {e.name.slice(0, 20)}
                            </div>
                        </Link>
                    </div>
                    </>
                )
            })}
        </div>
    </div>
  )
}

export default Quicklist