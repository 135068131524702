import React, { useState, useEffect } from 'react'
import styles from "./Messangers.module.css"
import { PiArrowFatUpDuotone } from "react-icons/pi";
import {Link} from "react-router-dom"

//mui import 
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';

import TelegramIcon from '@mui/icons-material/Telegram';
import MessageIcon from '@mui/icons-material/Message';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';


const actions = [
    { icon: <a href="tel:+998977590112"><PhoneEnabledIcon/></a>, name: 'Phone',link:''},
    { icon: <Link to="contact"><MessageIcon /></Link> , name: 'Message' },
    { icon: <a href="https://t.me/me_kamoloff"><TelegramIcon/></a>, name: 'Telegram' },
];

function Messangers() {
    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
        // 300px dan katta bo'lganda
        if (window.scrollY > 300) {
            setIsScrolled(true);
        } else {
            // 300px dan kichik bo'lganida
            setIsScrolled(false);
        }
    };
    const topscrolbtn = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    useEffect(() => {
        // Scroll o'zgarishlarini eshitish uchun "handleScroll" funktsiyasini qo'shish
        window.addEventListener('scroll', handleScroll);

        // Komponent o'chirilganda hodisalarni o'chirish
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div>
             {isScrolled && (<div>
                <div className={styles.Plusbox}>
                <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
                    <SpeedDial
                        FabProps={{ size: "medium", style: { backgroundColor: "#002579" } }}
                        ariaLabel="SpeedDial basic example"
                        sx={{}}
                        icon={<SpeedDialIcon />
                        
                    }
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                styles={{with:"40px"}}
                            />
                        ))}
                    </SpeedDial>
                </Box>
            </div>
            <div className={styles.messages}>
               
                    <div className={styles.topscrol} onClick={topscrolbtn}>
                        < PiArrowFatUpDuotone />
                    </div>
                
            </div>
             </div>)}
        </div>
    )
}

export default Messangers