import React, { useEffect, useState } from 'react'
import styles from './CategoryLeft.module.css'
import { FaArrowRight } from 'react-icons/fa'
import BIG from '../../images/big.png'
import { Fade } from 'react-reveal'
import { Link } from "react-router-dom"

//import mui
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RiArrowRightSFill } from "react-icons/ri";
import axios from "axios"
function CategoryLeft(sellers) {
    const [category, setcategory] = useState([])
    const [product, setproduct] = useState([])
    const [expanded, setExpanded] = React.useState(true);
    const handleChange = () => {
        setExpanded((prev) => !prev);
    };
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}` + '/class', {
            auth: {
                username: 'magniticadmin',
                password: 'Magnitic??[AWTQZTLW]]'
            }
        })
            .then((res) => {
                setcategory(res.data)
            })
        axios.get(`${process.env.REACT_APP_API}`, {
            auth: {
                username: 'magniticadmin',
                password: 'Magnitic??[AWTQZTLW]]'
            }
        })
            .then((res) => {
                setproduct(res.data)
            })
    }, [])
    return (
        <div className={styles.seller}>
            <div className={styles.card}>
                <div className="row">
                    <div className={styles.accardionfull}>
                        {category.map((e) => {
                            return (
                                <>
                                    <Accordion  className={styles.accardion_main} >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon className={styles.accardion_icon} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            className={styles.accardion_text_back}
                                        >
                                            <Typography><span className={styles.accardion_text}>{e.name}</span></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails >
                                            <Typography >
                                                {
                                                    product.map((j) => {
                                                        return (
                                                            <>
                                                                <ul className={styles.Accordion_menu_ul}>
                                                                    <li>
                                                                        {j.productclass.name === e.name ?
                                                                            <div>
                                                                                <div className={styles.accardion_item_tex}>
                                                                                    {/* <div className={styles.accardion_img}><img class="w-100"  src={j.img} alt="" /></div> */}
                                                                                    <div><Link to={`/full/${j.id}`} className={styles.text} state={sellers[j]}> {j.title}</Link></div>
                                                                                    <div><RiArrowRightSFill /></div>
                                                                                </div>
                                                                            </div>
                                                                            : <div></div>}
                                                                    </li>
                                                                </ul>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    
                                </>
                            )
                        })}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoryLeft