import  React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styles from "./Parametr.module.css"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { MdOutlineWifiCalling3 } from "react-icons/md";
import { FaTelegramPlane } from "react-icons/fa";
import axios from "axios"
import { useLocation } from 'react-router-dom';

function Parametr(props) {
  const {children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

Parametr.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [data,setdata] = useState([])
  let location = useLocation();
  var id = location.pathname.slice(6)
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}` + `/${id}`,{auth: {
        username: 'magniticadmin',
        password: 'Magnitic??[AWTQZTLW]]'
    }})
    .then((res) => {
        setdata(res.data)
    })
  },[])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
//  console.log('text',data)
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Описание" {...a11yProps(0)} />
          <Tab label="Технические параметры" {...a11yProps(1)} />
          <Tab label="контакты" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <Parametr value={value} index={0}>
      <div dangerouslySetInnerHTML={{ __html: data.descript_text }}></div>
      </Parametr>
      <Parametr value={value} index={1}>
      <div>Технические параметры:</div>
                    <table class="table table-bordered ">
                        <thead>
                            <tr>
                                <th scope="col">№</th>
                                <th scope="col">Характеристика</th>
                                <th scope="col">Значение</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.description?.map((e, i) => {
                                return (
                                    <tr>
                                        <td>{i+1}</td>
                                        <td>{e.dec_title}</td>
                                        <td>{e.dec_info}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
      </Parametr>
      <Parametr value={value} index={2}>
        <div><MdOutlineWifiCalling3 className='me-2'/><a className={styles.link} href='tel:+998909155094'>+998909155094</a> </div>
        <div><FaTelegramPlane className='me-2'/> <a className={styles.link} href='https://t.me/PROTEK_LLC'>https://t.me/PROTEK_LLC</a></div>
      </Parametr>
    </Box>
  );
}