import React, { useRef, useState } from 'react';
import styles from './herosection.module.css'

import { Fade } from 'react-reveal'
import { Link } from "react-router-dom"
//import img
import heroimg from "./heroimg/slidermain002.png"
import heroimg1 from "./heroimg/slidermain004.png"
import heroimg2 from "./heroimg/slidermain001.png"
import heroimg3 from "./heroimg/slidermain003.png"

//React lazy img
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

//swiper

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';

function HeroSection() {
  return (
    <div className='row container mx-auto'>
      <div className='col-lg-6 col-md-12 col-sm-12'>
        <div className={styles.main}>
          <Fade bottom>
            <h1 className={styles.h1}>
              ВСЕ ПРОДУКТЫ СЕЙЧАС ДОСТУПНЫ
            </h1>
          </Fade>
          <Fade bottom>
            <p className={styles.p}>
              Всю продукцию вы можете посмотреть и купить онлайн.

            </p>
          </Fade>
          <Fade bottom>
            <div className={styles.twobtn}>
              <div className='me-2'> <Link to="about/"><button className={styles.herobtn1}>О Компании</button></Link></div>
              <div><Link to="product/"><button className={styles.herobtn2}>Все Продукты</button></Link></div>
            </div>
          </Fade>
        </div>

      </div>
      <div className='col-lg-6 col-md-12 col-sm-12'>
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <LazyLoadImage
              alt="slider"
              effect="blur"
              src={heroimg}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage
              alt="slider"
              effect="blur"
              src={heroimg1}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage
              alt="slider"
              effect="blur"
              src={heroimg2}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage
              alt="slider"
              effect="blur"
              src={heroimg3}
            />
          </SwiperSlide>
        </Swiper>
      </div>

    </div>

  )
}

export default HeroSection
