import React, { useEffect, useRef, useState } from 'react'
import CategoryLeft from '../CategoryLeft/CategoryLeft'
import styles from './Products.module.css'
import { FaArrowRight } from 'react-icons/fa'
import BIG from '../../images/big.png'
import { Fade } from 'react-reveal'
import { Link } from "react-router-dom"

//import mui
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RiArrowRightSFill } from "react-icons/ri";
import axios from "axios"

//React lazy img 
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Bestsellers({ sellers, title, label, show, showbigimage }) {
    const formref = useRef()

    const [category, setcategory] = useState([])
    const [product, setproduct] = useState([])
    const [brands, setbrands] = useState([])
    const [filtercategory, setfiltercategory] = useState([])
    const [filtercategorydata, setfiltercategorydata] = useState('')
    const [filterbrend, setfilterbrend] = useState([])
    const [topproduct, settopproduct] = useState([])
    const [expanded, setExpanded] = React.useState(true);
    var checkedValues
    var valuelist

    const handleChange = () => {
        setExpanded((prev) => !prev);
    };
    function FilterBrendbtn() {
        const formElements = formref.current.elements;

        // Barcha input elementlarini tanlangan holatlarini olish
        const checkedInputs = Array.from(formElements).filter((element) => element.type === 'checkbox' && element.checked);

        // Tanlangan inputlarning qiymatlari yoki qo'shimcha amallar
        checkedValues = checkedInputs.map((input) => input.value);
        console.log('Checked Inputs:', checkedValues);
        const valuelist = checkedValues.join('&');
        console.log(valuelist);
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}` + '/category', {
            auth: {
                username: 'magniticadmin',
                password: 'Magnitic??[AWTQZTLW]]'
            }
        })
            .then((res) => {
                setcategory(res.data)
            })
        axios.get(`${process.env.REACT_APP_API}`, {
            auth: {
                username: 'magniticadmin',
                password: 'Magnitic??[AWTQZTLW]]'
            }
        })
            .then((res) => {
                setproduct(res.data)
            })
        axios.get(`${process.env.REACT_APP_API}` + '/brand', {
            auth: {
                username: 'magniticadmin',
                password: 'Magnitic??[AWTQZTLW]]'
            }
        })
            .then((res) => {
                setbrands(res.data)
            })
        axios.get(`${process.env.REACT_APP_API}` + `/?search=${filtercategory}`, { //&brands=[magnitiy , dhd, kjshf]
            auth: {
                username: 'magniticadmin',
                password: 'Magnitic??[AWTQZTLW]]'
            }
        })
            .then((res) => {
                setfiltercategorydata(res.data)
            })
        // axios.get(`${process.env.REACT_APP_API}` + `/?search=${valuelist}`, { //&brands=[magnitiy , dhd, kjshf]
        //         auth: {
        //             username: 'magniticadmin',
        //             password: 'Magnitic??[AWTQZTLW]]'
        //         }
        //     })
        //         .then((res) => {
        //             setfilterbrend(res.data)
        //         })
        axios.get(`${process.env.REACT_APP_API}` + `/topproduct`, { //&brands=[magnitiy , dhd, kjshf]
            auth: {
                username: 'magniticadmin',
                password: 'Magnitic??[AWTQZTLW]]'
            }
        })
            .then((res) => {
                settopproduct(res.data)
            })

        document.body.scrollTop = 200;
        document.documentElement.scrollTop = 200;
    }, [filtercategory])
    function Openfull() {

    }
    return (
        <>
            <div className={styles.seller}>
                <div className={styles.card}>
                    <div className="row gx-0">
                        <div className="col-lg-3 col-md-6 col-sm-12 ps-2 pe-2">
                            <div className={styles.AccordionLeftStyle}>
                                <CategoryLeft />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 ">
                            <div className={styles.card_full}>
                                <div className="row gx-0">
                                    {sellers.map((e, i) => (                         //(filtercategorydata == '' ? sellers : filtercategorydata)
                                        <div className='col-lg-4 col-md-6 col-sm-12 px-3' key={i} >
                                            <div className={styles.card_main_item}>
                                                <Link to={`/full/${e.id}`} className={styles.link} state={sellers[i]}>
                                                    <Fade bottom>
                                                        <div className={styles.main}>
                                                            <div className={styles.imagediv}>
                                                                <LazyLoadImage
                                                                    alt={e.title}
                                                                    effect="blur"
                                                                    src={e.img}
                                                                    className={styles.image} />
                                                            </div>
                                                        </div>
                                                        <div className={styles.content}>
                                                            <div className={styles.bestCategory}>{e.category.name.slice(0, 30)}</div>
                                                            <div className={styles.bestName}> {e.title.toUpperCase().slice(0, 32)}</div>
                                                            <div className=''><button className={styles.fulbtn}>Подробнее</button></div>
                                                        </div>

                                                    </Fade>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className={styles.brandfull}>
                                <label htmlFor="" className={styles.brandtitle}>Бренды</label>
                                <hr />
                                <form action="" ref={formref}>
                                    {brands.map((b) => {
                                        return (
                                            <>
                                                <input type="checkbox" name="Magnitic" id="" value={b.name} onChange={(e) => { setfilterbrend(e.target.value) }} /> <label className={styles.brandlabel} htmlFor="">{b.name}</label>
                                                <br />
                                            </>
                                        )
                                    })}
                                </form>
                                <br />
                                <button className={styles.brandbtn} onClick={FilterBrendbtn}>Фильтр</button>
                            </div>
                            <div className={styles.categoryfull}>
                                <div className={styles.brandtitle}>Категории</div>
                                <hr />
                                {
                                    category.map((c) => {
                                        return (
                                            <>
                                                <div onClick={() => setfiltercategory(c.name)} className={styles.category_name}><span className={styles.ctegory_item}> {c.name}</span></div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div className={styles.topproductfull}>
                                <div className={styles.topproducttitle}>Лучшие продукты</div>
                                <hr />

                                <div className='row'>
                                    {topproduct.map((e) => {
                                        return (
                                            <>
                                                <div className="col-4">
                                                    <img className={styles.topproimg} src={e.img} alt="" />
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className={styles.AccordionLeftStyle}>
                                <CategoryLeft />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bestsellers
