import React,{useEffect,useState} from 'react' 
import logo from "./img/logoremoveback.png"
import "./footer.css"
import {Link} from "react-router-dom"
import mainlogo from "../../assets/Images/protek-white-logo.png"
//icon
import {BsTelegram} from 'react-icons/bs';
import {BsFacebook} from 'react-icons/bs';
import {AiOutlineInstagram} from 'react-icons/ai';
import {AiFillYoutube} from 'react-icons/ai';

//react icon
import { AiFillInstagram } from 'react-icons/ai';
import { BiLogoFacebook } from 'react-icons/bi';
import { BiLogoTelegram} from 'react-icons/bi'
import { BsFillTelephoneFill} from 'react-icons/bs'
import {HiLocationMarker} from 'react-icons/hi'

function Footer() {
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },[])
  return (
   <div >
    <div class="footer">
    <div className='container'>
      <div className="row m-0 ">
      <div className="col-lg-3 col-md-6 col-sm-12">
                <div><Link className='footer_item' to="/"> <img className='logofooter' src={mainlogo} alt="" /></Link> </div>
      
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
              <ul className='menu'>
                <li className='title'>СТРАНИЦЫ</li>
                <li><Link className='footer_item' to="/">Главная</Link> </li>
                <li><Link className='footer_item' to="/product">Продукция</Link></li>
                <li><Link className='footer_item' to="/about"> О Нас</Link></li>
                <li><Link className='footer_item' to="/contact">Контакты</Link> </li>
              </ul>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <ul className='menu'>
            <li className='title'>КАТЕГОРИИ</li>
            <li>Лабораторное оборудование</li>
            <li>Испытательное оборудование</li>
            <li>Измерительные приборы</li>
            <li>Геодезическое оборудование</li>
          </ul>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 ">
            <ul className='menu'>
              <li className='title g-0'>АДРЕСС</li>
              <li>Тел: +99897 959-01-12</li>
              <li> Электронная почта: Protek145@gmail.com</li>
              </ul>
          
          </div>
      </div>      
      <div>
        <div className="social">
          <div class="icons">
              <a href="#"><i class="fab fa-youtube ytbe"></i></a>
              <a href="#"><i class="fab fa-facebook gthb"></i></a>
              <a href="#"><i class="fab fa-instagram intgrm"></i></a>
            <a href="https://t.me/me_kamoloff"><i class="fab fa-telegram-plane tlgrm"></i></a>
          </div>
        </div>
      </div>
    </div>
    </div>

    <div className='bootom-footer'>Авторское право © 2023 Protek. Все права защищены.</div>

   </div>
  )
}

export default Footer