import AppRouter from "./Router/Router";
import Loading from "./Components/Loading/Loading";
import { useEffect, useState } from "react";

function App() {
  const [isloading, setIsLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => { setIsLoading(false) }, 2000)
  }, [])
  if (isloading) {
    return <Loading />
  }

  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
