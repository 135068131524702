import React from "react";
import styles from "./About.module.css";
import Fade from "react-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumb from "../../../Components/Breadcrumps/Breadcrumps";
import {
  faHeart,
  faLightbulb,
  faStar,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCreativeCommons,
  faFacebook,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCross,
  faIndustry,
  faPenRuler,
  faTractor,
} from "@fortawesome/free-solid-svg-icons";

function About() {
  const crumbs = {
    "menu1": "Главная",
    "menu1link": "/",
    "menu2": "Продукция",
    "menu2link": "/product",
    "menu3": "Контакты",
    "menu3link": "/contact",
    "main": "О Нас",
  }
  return (
    <div>
      <Breadcrumb data={crumbs} />
      <Fade bottom>
        <div className={styles.maincolor}>
          <div className={styles.header}>
            <h1>О нас</h1>
            <p>
              ООО «PROTEK GROUP» является крупнейшим поставщиком лабораторной техники в Узбекистане. Компания успешно развивает такие сегменты, как: сервисная обслуживание, ремонт лабораторных приборов. доставка в Узбекистан запасных частей и расходных материалов для лаборатории.
            </p>
            <p>Целью Нашей компании является  снабжение лаборатории Республики Узбекистан качественными приборами.</p>
            <p className="font-bold">Наши принципы:</p>
            <p>
              <ul>
                <li>комплексные поставки лабораторных приборов и оборудования для лаборатории Узбекистана ведущих отечественных и импортных производителей ;</li>
                <li>установка и ремонт различной лабораторной техники;</li>
                <li>участие в тендерах, аукционах и конкурсных процедурах;</li>
                <li>доставка приобретаемыми у нас приборов по Узбекистану;</li>
                <li>обучение персонала работе с приобретаемыми у нас приборами.</li>
              </ul>
            </p>
            <p>Репутация надежного, ответственного и опытного партнера, всегда выполняющего свои обязательства, позволила компании ООО «PROTEK GROUP» построить прочные отношения с большинством ведущих мировых производителей.</p>
            <p>ООО «PROTEK GROUP» сегодня развивают национальный рынок в нескольких сегментах по поставку лабораторной техники.</p>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default About;
