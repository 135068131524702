import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';
import {Link} from "react-router-dom"

import "../Breadcrumps/Breadcrumps.css"
import { useState } from 'react';
import { useEffect } from 'react';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function BasicBreadcrumbs(data) {
    const [crumpmenu,setcrupmenu] = useState([])
    useEffect(() => {
       setcrupmenu(data.data)
    },[])
    
  return (
    <div role="presentation" onClick={handleClick} className='Breadcrumbfull'>
      <Breadcrumbs aria-label="breadcrumb" className='crumbsall'>
        <Link 
          underline="hover" 
          className='crumlink' 
          to={crumpmenu.menu1link}>
          {crumpmenu.menu1}
        </Link>
        <Link
          underline="hover"
          className='crumlink'
          to={crumpmenu.menu2link}
        >
          {crumpmenu.menu2}
        </Link>
        <Link
          underline="hover"
          className='crumlink'
          to={crumpmenu.menu3link}
        >
          {crumpmenu.menu3}
        </Link>
        <Typography  className='crumlinkmain'>{crumpmenu.main}</Typography>
      </Breadcrumbs>
    </div>
  );
}