import React,{useState,useEffect} from "react";
import styles from "./Contact.module.css";
import Fade from "react-reveal";
import   Breadcrumb  from "../../Components/Breadcrumps/Breadcrumps";
import axios from "axios"


//import img
import backimg from "../../assets/Images/contact-us_36325-2135.jpg"
function Contact() {
  const [inputname, setInputname] = useState('');
  const [inputnomer, setInputnomer] = useState('');
  const [inputemail, setInputemail] = useState('');
  const [inputmessage, setInputmessage] = useState('');
  const [message, setMessage] = useState('');
  const crumbs = {
    "menu1":"Главная",
    "menu1link":"/",
    "menu2":"Продукция",
    "menu2link":"/product",
    "menu3":"О Нас",
    "menu3link":"/about",
    "main":"Контакты ",
  }
  const handleInputChangename = (event) => {
    
    setInputname(event.target.value);

 };
  const handleInputChangenomer = (event) => {
   
    setInputnomer(event.target.value);
  };
  const handleInputChangeemail = (event) => {
   
    setInputemail(event.target.value);
  };
  const handleInputChangemessage = (event) => {
    setInputmessage(event.target.value);
    
  };
  const sendMessageToTelegram = async () => {
    try {
      // Telegram Bot API url va tokeni
      const telegramApiUrl = `https://api.telegram.org/bot6813199382:AAEswT7LLYyPvdXseGFmxU8lrC9C-PoI5kA/sendMessage`;
      
      // To'g'ri formatda xabar ma'lumotlari
      const chatId = '2008990189'; // Sizning kanal yoki guruh IDsi
      const text = 'Assalomu alaykum, bu test xabar!';
      
      // API so'rovni amalga oshirish
      const response = await axios.post(telegramApiUrl, {
        chat_id: chatId,
        text: text,
      });
      
      // Yuborilgan xabarga javobni konsolega chiqaring
      console.log('Telegram javobi:', response.data);
      
      // Xabar muvaffaqiyatli yuborilganda React state-ni yangilang
      setMessage('Xabar muvaffaqiyatli yuborildi!');
    } catch (error) {
      // Xato bo'lganda uni chiqaring
      console.error('Xatolik yuz berdi:', error);
      setMessage('Xato yuz berdi. Iltimos, qayta urinib koring.');
    }
  };
  return (
    <Fade bottom>
      <Breadcrumb data={crumbs}/>
      <div className={styles.back}>
        <img className={styles.backimg} src={backimg}/>
        <div className={styles.backtext}>Контакты</div>

      </div>
      <div className={styles.maincolor}>
        <div className={styles.first}>
          <div>
            <h1>Контакт</h1>
            <p>Отправьте сообщение, и наша команда свяжется с вами в течение 24 часов.</p>
          </div>
          <div className={styles.form}>
            <input
              placeholder="Имя"
              style={{ color: "black" }}
              className={styles.inputfeild}
              onChange={handleInputChangename} 
            />
            <input
              placeholder="Номер телефона"
              type="number"
              style={{ color: "black" }}
              className={styles.inputfeild}
              onChange={handleInputChangenomer} 
            />
            <input
              type="email"
              placeholder="Электронная почта"
              style={{ color: "black" }}
              className={styles.inputfeild}
              onChange={handleInputChangeemail} 
            />
            <textarea
              placeholder="Сообщение"
              rows={7}
              className={styles.textarea}
              onChange={handleInputChangemessage} 
            />
            <button type="отправлять" className={styles.btn} onClick={sendMessageToTelegram}>
            Отправлять
            </button>
          </div>
        </div>
        <div className={styles.second}>
          <div  className={styles.secondcontent} >
            <div className={styles.smallcontent} >
              {/* <p>Расположение</p>
              <h4>г.Ташкент, Сергильский район, улица Нилуфар 2А</h4> */}
            </div>
            <div  className={styles.smallcontent}>
              <p>РАБОЧЕЕ ВРЕМЯ</p>
              <h4>С понедельника по пятницу с 9:00 до 18:00. </h4>
              <p>Наша служба поддержки доступна 24 часа в сутки</p>
            </div>
            <div className={styles.smallcontent}>
              <p>СВЯЗАТЬСЯ С НАМИ</p>
              <h4>+99897 959-01-12</h4>
              <p>info.protek@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default Contact;
