import React from 'react'
import styles from './Bestsellers.module.css'
import { FaArrowRight } from 'react-icons/fa'
import BIG from '../../images/big.png'
import { Fade } from 'react-reveal'
import {Link} from "react-router-dom"

//React lazy img 
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Bestsellers({ sellers, title, label, show, showbigimage }) {
function Openfull(){
  
}
  return (
    <div className={styles.seller}>
                            <div className={styles.card_full}>
                                <div className="row me-0 ms-0">
                                {sellers?.map((e, i) => (
                                    <div className='col-lg-3 col-md-6 col-sm-12' key={i} >
                                        <div className={styles.card_main_item}>
                                        <Link to={`/full/${e.id}`} className={styles.link} state={sellers[i]}>
                                            <Fade bottom>
                                                <div className={styles.main}>
                                                    <div className={styles.imagediv}>
                                                        <LazyLoadImage
                                                            alt={e.title}
                                                            effect="blur"
                                                            src={e.img}
                                                            className={styles.image} />
                                                        {/* <img src={e.img} alt={e.name} className={styles.image} /> */}
                                                    </div>
                                                </div>
                                                <div className={styles.content}>
                                                    <div className={styles.bestCategory}>{e.category.name.slice(0, 30)}</div>
                                                    <div className={styles.bestName}> {e.title.toUpperCase().slice(0, 28)}</div>
                                                    <div className=''><button className={styles.fulbtn}>Подробнее</button></div>
                                                    {/* toUpperCase() */}
                                                </div>

                                            </Fade>
                                        </Link>
                                        </div>
                                        </div>
                                ))}
                                </div>
                            </div>
    </div>


  )
}

export default Bestsellers
