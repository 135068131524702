import React from "react";
import Slider from "react-slick";
import styles from "./Categoryslider.module.css"
import {Link} from "react-router-dom"
//react-icon import 
import { FcNext } from 'react-icons/fc';
import { FcPrevious } from 'react-icons/fc';

//react lazy img
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SimpleSlider = (data) => {
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite:true,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <FcNext  style={{backgroundColor:"blue"}}/>,
    prevArrow: <FcPrevious />,
    responsive: [
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 890,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  
  };
console.log(data.data)
  return (
    <div className={styles.categorysliderfull}>
      <h2> Похожие товары </h2>
      <Slider {...settings}>
        {
            data.data?.map((e,i) =>{
                return(
                    <div className={styles.SilckSlider}>
                       <div className={styles.slicCard}>
                            <div>
                            <LazyLoadImage
                                alt="category slider"
                                effect="blur"
                                src={e.img}
                                className={styles.slickcardimg} />
                            </div>
                            <div>
                              <div className={styles.categoryname}>{e.category.name}</div>
                              <div className={styles.cardname}>{e?.title?.slice(0,30)}...</div>
                              <div><Link  to={`/full/${e.id}`} state={data.data[i]}><button className={styles.slidercardbtn}>Подромнии</button></Link> </div>
                            </div>
                       </div>
                    </div>
                )
            })
        }
      </Slider>
    </div>
  );
};

export default SimpleSlider;