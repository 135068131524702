import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import CategoryLeft from '../CategoryLeft/CategoryLeft'
import SliderSingle from '../SliderSinglePage/SliderSingle'
import styles from './singlePage.module.css'
import Parametr from '../Parametr/Parametr'
import axios from "axios"

//import slider page
import Simpleslider from "../CategorySlider/Categoryslider"
import { Category } from '@mui/icons-material'

//React lazy img
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function SinglePage() {
    const params = useParams()
    
    const [data, setdata] = useState({})
    const [url, seturl] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [categoryVal, setcategoryVal] = useState([])
    const [slider,setslider] = useState([])
    useEffect(() => {
        // seturl((window.location.href)?.slice(27))
        let id = params.id
        if (id == "") {
            id = url
        }
        axios.get(`${process.env.REACT_APP_API}` + `/${id}`,{auth: {
            username: 'magniticadmin',
            password: 'Magnitic??[AWTQZTLW]]'
        }})
            .then((ress) => {
                setdata(ress.data)
                setLoading(true)
                setcategoryVal(data.category.name)
            })
            .catch((err) => {
                setLoading(false)
            })
        console.log("categoryVal",categoryVal)
        axios.get(`${process.env.REACT_APP_API}`+ `/?search=${categoryVal}` ,{auth: {  //
            username: 'magniticadmin',
            password: 'Magnitic??[AWTQZTLW]]'
        }})
        .then((res) => {
            setslider(res.data)
        })
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [params])
    console.log(params)
    
    return (
        <div>
            <div className={styles.singleFullpage}>
            <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
                <div className={styles.AccardionLeftstyle}>
                  <CategoryLeft />  
                </div>
            </div>
            <div className="col-lg-9 col-md-6 col-sm-12">            
            <div className={styles.Scrolpage}>
                <div className="row">
                    <div className="col-lg-5 col-md-12 col-sm-12">
                        <div className={styles.singimg}>
                            <div className={styles.imgdiv}>
                            <LazyLoadImage
                                alt={data.name}
                                effect="blur"
                                src={data.img} />
                            </div>
                        </div>
                        {/* <SliderSingle/> */}
                    </div>
                    <div className="col-lg-7 col-md-12 col-sm-12">
                    <div className={styles.title}>{data.title}</div>
                    <hr />
                        <div className={styles.singletitle}>{data.name}</div>
                        <div dangerouslySetInnerHTML={{ __html: data.info }}></div>

                    </div>
                </div>
                {/* <div dangerouslySetInnerHTML={{ __html: data.descript_text }}></div> */}
                {
                    data.description?
                    <div>
                    <Parametr />
                </div>
                    :
                    <div></div>
                }
            </div>
            </div> 
             
            </div>
            </div>
            <div className={styles.SimpleSlider}>
              <Simpleslider  data={slider}/>  
            </div>
            
        </div>
    )
}

export default SinglePage