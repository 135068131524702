import React, { useEffect, useState } from 'react'
import styles from './Store.module.css'
import Fade from 'react-reveal'
import SearchBar from '../../../Components/SearchBar/SearchBar'
import Bestsellers from '../../../Components/Bestsellers/Bestsellers'
import Product from "../../../Components/Products/Products"
import axios from "axios"
//mui
// import styles01 from "../../Support/Support.module.css";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

//import img
import backimg from "../../../assets/Images/product.jpg"


import Breadcrumps from "../../../Components/Breadcrumps/Breadcrumps"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccessAlarm, ThreeDRotation, KeyboardArrowUpIcon } from '@mui/icons-material';
import KeyboardControlKeySharpIcon from '@mui/icons-material/KeyboardControlKeySharp';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import { faArrowDown, faArrowUp, faCross, faMinus, faPlus, faPlusCircle, faLessThan, faBars } from "@fortawesome/free-solid-svg-icons";


import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';

function Store() {
  const [category, setcategory] = useState([])
  const [alldata, setalldata] = useState([])
  const [expandedIndex, setExpandedIndex] = useState(1);
  const [categoryval, setcategoryval] = useState([])
  const [categoryId, setcategoryId] = useState('')
  const [countPage, setCountPage] = useState(0)
  const [page, setPage] = useState(1)

  const [age, setAge] = React.useState('Все');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const toggleAnswer = (index) => {
    if (index === expandedIndex) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}` + '/category', {
      auth: {
        username: 'magniticadmin',
        password: 'Magnitic??[AWTQZTLW]]'
      }
    })
      .then((res) => {
        setcategory(res.data)
      })
    axios.get(`${process.env.REACT_APP_API}` + `/pagination?page=${page}` + `&search=${(categoryId == 5 || categoryId == '') ? '' : categoryId}`, {
      auth: {
        username: 'magniticadmin',
        password: 'Magnitic??[AWTQZTLW]]'
      }
    })
      .then((res) => {
        setCountPage(Math.ceil(res.data.count / 9))
        setcategoryval(res.data.results)
      })
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [page, categoryId])
  const crumbs = {
    "menu1": "Главная",
    "menu1link": "/",
    "menu2": "О Нас",
    "menu2link": "/about",
    "menu3": "Контакты",
    "menu3link": "/contact",
    "main": "Продукция",
  }
  console.log("link", process.env.REACT_APP_API)
  console.log(categoryval)
  return (
    <div className={styles.storefull}>
      <Breadcrumps data={crumbs} />
      <div className={styles.back}>
         <img className={styles.backimg} src={backimg} alt='img'/>
         <div className={styles.backtext}>Продукция</div>
      </div>
      
      <div className={styles.header}>
      <div>
      </div>
      </div>
      <Fade bottom>
        <div className={styles.besserfull}>
          <div>
            <FormControl className={styles.SelectFull}>
              <InputLabel id="demo-simple-select-autowidth-label">фильтр</InputLabel>
              {/* <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={age}
                onChange={handleChange}
                autoWidth
                label="филтир"
                className={styles.selectMain}
              >
                <MenuItem value="Bce"  onClick={(() => {
                        setcategoryId()
                        setcategoryId(5)
                        setPage(1)
                      })}>Bce</MenuItem>
                {
                  category?.map((res) => {
                    return (
                      <MenuItem value={res.name} onClick={(() => {
                        setcategoryId()
                        setcategoryId(res.id)
                        setPage(1)
                      })}>{res.name}</MenuItem>
                    )
                  })
                }
              </Select> */}
            </FormControl>
            {console.log(categoryId)}
          </div>
          {categoryval.length > 0 ?
            <div>
              < Product sellers={categoryval} />
            </div>
            :
            <div>
              <Box sx={{ display: 'flex' ,justifyContent:'center'}}>
                <CircularProgress />
              </Box>
            </div>}

          <Stack spacing={1} className={styles.Paginator}>
            <Pagination page={page} onChange={handleChangePage} count={countPage} color="primary" variant="outlined" shape="rounded" />
          </Stack>
        </div>
      </Fade>
    </div>
  )
}

export default Store
