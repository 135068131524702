import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import axios from "axios"
import './navbar.css'
import { FaBars, FaSearch, FaTimes, FaYahoo } from 'react-icons/fa'
import { RiArrowRightSFill } from "react-icons/ri";
// import Logo from '../../images/4UnXwoQPTBHjZGDJ1LNhcNeOJ4s.jpg'
import Logo from "./navimg/proteklogo2.png"

//mui
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RxHamburgerMenu } from "react-icons/rx";
import { LiaTimesSolid } from "react-icons/lia";
import { FiPlus } from "react-icons/fi";
import { FaCaretRight } from "react-icons/fa";
import { AiOutlineMinus } from "react-icons/ai";


import { Link } from "react-router-dom"
function NavBar(sellers) {
  const [click, setClick] = useState(false)
  const [category, setcategory] = useState([])
  const [product, setproduct] = useState([])
  const [searchval,setsearchval] = useState([])

  const handleClick = () => setClick(!click)
  const [isFixed, setFixed] = useState(false);



  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}` + '/category', {
      auth: {
        username: 'magniticadmin',
        password: 'Magnitic??[AWTQZTLW]]'
      }
    })
      .then((res) => {
        setcategory(res.data)
      })
    axios.get(`${process.env.REACT_APP_API}`, {
      auth: {
        username: 'magniticadmin',
        password: 'Magnitic??[AWTQZTLW]]'
      }
    })
      .then((res) => {
        setproduct(res.data)
      })
     

    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 300) {
        setFixed(true);
      } else {
        setFixed(false);
      }

    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const Searchvalue = (e) => {
    setsearchval(e.target.value)
  }
  console.log(searchval)

  const navbarStyle = {
    position: isFixed ? (click==false)? 'fixed' : 'relative' : 'static',
    top: 0,
    width: '100%',
    backgroundColor: 'white',
    paddingTop: '2px',
    paddingBottom: '15px',
    zIndex: 1000,
  };
  const accordionStyle = {
    boxShadow: 'none', // Box shadow
    border: 'none', // Border
    borderRadius: '8px', // Optional: If you want rounded corners
    backgroundColor:'#F8F8F8'
  };

  return (
    <>
      <div style={navbarStyle}>
        <nav className="navbar">
          <div className="nav-container">
            <div style={{ display: "flex", alignItems: "center" }} >
              <a href="/">
                <img className='logoimg' src={Logo} alt="Tech Wave Logo" />
              </a>
            </div>
            <ul className={click ? 'nav-menu active mt-4' : 'nav-menu mt-4'}>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Главная
                </NavLink>
              </li>
              {/* <li class="dropdown nav-item" >
                Продукция <RiArrowRightSFill/>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <RiArrowRightSFill/></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
            </ul>
          </li> */}
              <li className="nav-item product_desktop">
                <NavLink
                  exact
                  to="product/"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Продукция
                </NavLink>
              </li>
              <li className='nav-item product_mobile'>      
              <Accordion style={accordionStyle} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography ><span className='accardion_maintext'>Продукция</span> </Typography>
                </AccordionSummary>
                <Typography className="navbar_category_item01"><Link  onClick={handleClick} className='link' to='/product'>Все продукция</Link> </Typography>
                {category.map((e) => {
                  return (
                    <>
                      <Accordion style={accordionStyle }>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          
                          <Typography>{e.name === "все" ?"":e.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            {
                              product.map((j) => {
                                return (
                                  <>
                                    <ul className='navbar_category_item'>
                                      <li>
                                        {j.category.name === e.name ?
                                          <div>
                                            <div><Link to={`/full/${j.id}`}  onClick={handleClick} className='link_styles' state={sellers[j]}><FaCaretRight /> {j.title}</Link></div>
                                          </div>
                                          : <div></div>}
                                      </li>
                                    </ul>
                                  </>
                                )
                              })
                            }
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )

                })}
              </Accordion></li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/about"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  О Нас
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/contact"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Контакты
                </NavLink>
              </li>
              <li className="nav-itemq">
                <div className="input-feild-div">
                  <input
                    placeholder="Поиск товара"
                    style={{ color: '#CFCFCF' }}
                    className="input-feild"
                    onChange={Searchvalue}
                  />
                 <Link to=""><FaSearch color="#C4C4C4" /></Link> 
                </div>
              </li>
            </ul>
            <div className="nav-icon" onClick={handleClick}>
              {click ? <LiaTimesSolid  /> : <RxHamburgerMenu  />}
            </div>
          </div>
        </nav>

      </div>

    </>
  )
}

export default NavBar
