import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home/Home";
import NavbarCom from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import Store from "../Pages/Home/Store/Store";
import About from "../Pages/Home/About/About";
import Contact from "../Pages/Contact/Contact";
import Singlefull from "../Components/Single/SinglePage"
import QuicklistPage from "../Components/QuicklistPage/QuicklistPage";
import Search from "../Components/Search/Search"

const AppRouter = () => {
  return (
    <BrowserRouter>
      {/* <TopNav/> */}
      <NavbarCom />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="product" element={<Store />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path='/full/:id' element={< Singlefull />} />
        <Route path="quicklistproduct" element={< QuicklistPage/>}/>
        <Route path="search" element={<Search/>}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRouter;
