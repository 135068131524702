import React from "react";
import Slider from "react-slick";
import styles from "./Producmainslider.module.css"
import {Link} from "react-router-dom"
//react-icon import 
import { FcNext } from 'react-icons/fc';
import { FcPrevious } from 'react-icons/fc';
import { FiPlus } from "react-icons/fi";
import { FaAnglesRight } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";

//react lazy img
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#B0B0B0",width:"50px",height:"40px", display:"flex",justifyContent:"center",alignItems:"center",zIndex:"100px"}}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#B0B0B0",width:"50px",height:"40px", display:"flex",justifyContent:"center",alignItems:"center",zIndex:"1000"}}
        onClick={onClick}
      />
    );
  }

const Producmainslider = (data) => {
  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 890,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            infinite: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true
          }
        }
      ]
  
  };
console.log(data.data)
  return (
    <div className={styles.producslider}>
      <div className={styles.producttext}>НОВЫЕ ПОСТУПЛЕНИЯ </div>
        <div className={styles.producslidersetting}>
        <div>
      
      <Slider {...settings}>
        {
            data.data?.map((e,i) =>{
                return(
                    <div className={styles.SilckSlider}>
                       <div className={styles.slicCard}>
                            <div className={styles.cardimg}>
                            <LazyLoadImage
                                alt="category slider"
                                effect="blur"
                                src={e.img}
                                className={styles.slickcardimg} />
                            </div>
                            {/* <div>
                              <div className={styles.categoryname}>{e.category.name}</div>
                              <div className={styles.cardname}>{e?.title?.slice(0,30)}...</div>
                              <div><Link  to={`/full/${e.id}`} state={data.data[i]}><button className={styles.slidercardbtn}>Подромнии</button></Link> </div>
                            </div> */}
                       </div>
                      <Link  className={styles.link} to="/quicklistproduct" state={e.productclass.name}>
                       <div className={styles.slichovermain}>
                            <div className={styles.slichover}></div>
                            < FiPlus  style={{color:"#FFFFFF",fontSize:"40px"}} className={styles.slichovericon}/>
                            <div className={styles.slichovertitle}>Все {e.productclass.name} < FaAnglesRight/></div>
                        </div>
                      </Link>  
                      <div className={styles.title}>{e.productclass.name}</div>
                    </div>
                )
            })
        }
      </Slider>
    </div>
        </div>
    </div>
  );
};

export default Producmainslider;