import styles from "./home.module.css";
import HeroSection from "../../Components/HeroSection/HeroSection";
import Category from "../../Components/Category/Category";
import Tec from "../../images/image (3).png";
import Gear from "../../images/image (11).png";
import Gear1 from "../../images/image (7).png";
import laptop from "../../images/image (9).png";
import Watch from "../../images/image.png";
import { useState,useEffect } from "react";
import Bestsellers from "../../Components/Bestsellers/Bestsellers";
import Products from "../../Components/Products/Products"
import Fade from "react-reveal";
import Featured from "../../Components/Featured/Featured";
import Icons from "../../Components/Icons/Icons";
import PanetnerCarus from "../../Components/CarusPartner/Carus"
import {Link} from "react-router-dom"
import Map from "../../Components/GoogleMap/map"
import CountUp from "../../Components/CountUp/Countup"
import ProducmainSlider from "../../Components/Producmainslider/Producmainslider"
import Quicklist from "../../Components/Quicklist/Quicklist";
import Messangers from "../../Components/Messangers/Messangers"
import axios from "axios"
let data = []

//mui 

function Home() {
  const [mydata,setmydata] = useState([])
  const  [dataFive ,  setdataFive] = useState([])
  const [oneproduct,setoneproduct] = useState([])
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}` ,{auth: {
      username: 'magniticadmin',
      password: 'Magnitic??[AWTQZTLW]]'
  }})
    .then((res) => {
      let currentData = []
      for(let i = 0 ; i<8 ; i++){
          currentData.push(res.data[i])
          setdataFive([...currentData])
      }
    })
    axios.get(`${process.env.REACT_APP_API}`+`/producclass`,{auth: {
      username: 'magniticadmin',
      password: 'Magnitic??[AWTQZTLW]]'
  }})
    .then((res) => {
      setoneproduct(res.data)
    })
    console.log('datafive',dataFive)
  },[])

  const [icons, seticons] = useState([
    {
      id: 1,
      name: "Доставка",
      des: "Бесплатная доставка по Узбекистану",
      image: "./images/icon01.png",
    },
    {
      id: 1,
      name: "Поддержка 24x7",
      des: "Круглосуточная помощь",
      image: "./images/icon03.png",
    },
    {
      id: 1,
      name: "Гарантия",
      des: "Гарантия на все поставляемое оборудование",
      image: "./images/icon02.png",
    },
    {
      id: 1,
      name: "Сервис",
      des: "Удобный сервис и техническое обслуживание",
      image: "./images/icon04.png",
    }
  ]);
console.log("one",oneproduct)
  return (
    <div>
      <HeroSection />

      <ProducmainSlider data={oneproduct}/>
      {/* <Category Data={mydata} /> */}
      <div className={styles.product_color}>
      <div className={styles.product_home}>
      <div className={styles.producttext}>ПРОДУКЦИЯ</div>
      <Bestsellers
        title="Bestsellers"
        label="Browse all products"
        sellers={dataFive}
      />
      <div className={styles.btnbox}>
        <Link to='product' className={styles.link}><div className={styles.allproductbtn}>Все Продукты</div></Link> 
      </div>
      </div>        
      </div>
      <Quicklist/>
      <Bestsellers
        title="Featured Products"
        label="Browse all products"
        showbigimage
        show
        sellers={mydata}
      />
      {/* <Products
              title="Featured Products"
              label="Browse all products"
              showbigimage
              show
              sellers={mydata}/> */}
      <CountUp/>
      <Icons data={icons} />
      <PanetnerCarus/>
      {/* <Map/> */}
      <Messangers />

    </div>
  );
}

export default Home;
