import React from 'react'
import styles from './Category.module.css'
import { Fade } from 'react-reveal'
import { FaArrowRight } from 'react-icons/fa'

function Category(Data) {
  console.log("hamidullo",Data.Data)
  return (
    <div className={styles.seller}>
      <div className={styles.card}>
        {Data.Data?.map((e, i) => (
          <div className={styles.cardItem} key={i}>
            <Fade bottom>
              <div className={styles.main}>
                <div className={styles.imagediv}>
                  <img src={e.img} alt={e.name} className={styles.image} />
                </div>
                {/* <p className={styles.para}>{e.category.name}</p> */}
              </div>
              <div className={styles.content}>
                <div className={styles.cateName}>{e.category.name}</div>
                <div className={styles.productName}>{e.name}</div>
              </div>
            </Fade>
          </div>
        ))}
      </div>
    </div>
  // <div></div>
  )
}

export default Category
