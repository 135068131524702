import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import logo from "./logo/logo.png"
import logo1 from "../../assets/Images/thermo.png"
import logo2 from "./logo/logo2.png"
import logo3 from "./logo/logo3.png"
import logo4 from "./logo/logo4.png"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './styles.css';
import styles from "./Carus.module.css"

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard , Autoplay} from 'swiper/modules';

export default function App() {
  return (
    <>
      <Swiper
        // spaceBetween={30}
        // centeredSlides={true}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        loop={true}
        // pagination={{
        //   clickable: true,
        // }}
        breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper mt-5 mb-5"
      >
        <SwiperSlide className='logobox'><img className='logo' src={logo1} alt="Partner logo" /></SwiperSlide>
        <SwiperSlide className='logobox'><img className='logo' src={logo2} alt="Partner logo" /></SwiperSlide>
        <SwiperSlide className='logobox'><img className='logo' src={logo3} alt="Partner logo" /></SwiperSlide>
        <SwiperSlide className='logobox'><img className='logo' src={logo1} alt="Partner logo" /></SwiperSlide>
        <SwiperSlide className='logobox'><img className='logo' src={logo4} alt="Partner logo" /></SwiperSlide>
      </Swiper>
      {/* <div className='row mx-auto my-auto'>
        <div className="col-3"><span className={styles.partnetimg}> <img src={logo1} alt="" /></span></div>
        <div className="col-3"><span className={styles.partnetimg}> <img src={logo2} alt="" /></span></div>
        <div className="col-3"><span className={styles.partnetimg}> <img src={logo3} alt="" /></span></div>
        <div className="col-3"><span className={styles.partnetimg}> <img src={logo4} alt="" /></span></div>
      </div> */}
    </>
  );
}